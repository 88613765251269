import { useNavigate, useParams } from "react-router-dom";
import { useListing, useSimilar } from "../../hooks/listing";
import LoadingWrapper from "../../components/Loading/LoadingWrapper";
import { OrbitProgress } from "react-loading-indicators";
import utils from "../../utils";
import { renderHeader } from "../../helpers/listing";
import useGlobalConfigState from "../../stores/config";
import ListingKeyValue from "./ListingKeyValue";
import ListingInfo from "./ListingInfo";
import ListingCalculator from "./ListingCalculator";
import verified from "../../assets/verified.svg";
import BorderedButton from "../../components/Buttons/BorderedButton";
import ListingGallery from "./ListingGallery";
import { useEffect, useState } from "react";
import GradientButton from "../../components/Buttons/GradientButton";
import SearchResult from "../Search/SearchResult";
import noImage from "../../assets/no_image.png";
import axios from "axios";
import api from "../../api";
import useGlobalUserState from "../../stores/user";
import { Icon } from "../../components/Icon/Icon";

const Listing = ({
  id: propListingId,
  showSimilar = true,
  disableCalc = false,
}) => {
  const [galleryOpenCounter, setGalleryOpenCounter] = useState(0);
  const [galleryIndex, setGalleryIndex] = useState(0);
  const [descriptionCollapsed, setDescriptionCollapsed] = useState(true);
  const [favorites, setFavorites] = useState([]);
  const [showPhone, setShowPhone] = useState(false);
  const { listingId: paramListingId } = useParams();
  const listingId = propListingId || paramListingId;
  const configState = useGlobalConfigState();
  const navigate = useNavigate();
  const config = configState.get();
  const listing = useListing(listingId);
  const userState = useGlobalUserState();
  const user = userState.get();
  const similar = useSimilar(listing, showSimilar);
  let listingStructure = {};
  if (listing.deal_type) {
    listingStructure = config.listingStructures.find(
      (obj) =>
        obj.deal_type == listing.deal_type &&
        obj.listing_type == listing.listing_type &&
        obj.property_type == listing.property_type
    );
  }
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [listingId]);
  const headerStructure = listingStructure.top?.split(",") || [];
  const dateFormat = (date) => {
    const ndate = new Date(date).toLocaleDateString("ru-RU", {
      month: "long",
      day: "numeric",
    });
    return ndate;
  };

  const registerEvents = (ls) => {
    const parent = "listing";
    utils.event(`phone_from_listing`, ls.id);
    utils.event(
      "phone_from_listing",
      ls.id,
      {
        price: ls.price,
        deal_type: ls.deal_type,
        listing_type: ls.listing_type,
        property_type: ls.property_type,
        rooms: ls.num_rooms,
        area: ls.area,
        address: ls.address_string,
        source: parent,
        user_id: user.id,
      },
      "appsflyer"
    );
  };

  const infoStructure = listingStructure.info?.split(",") || [];
  const renderDescription = (listing) => {
    if (listing.description === null) return null;
    const isCollapsible = listing.description?.length > 300;
    return (
      <div className='flex flex-col items-start'>
        <p className='mb-5 text-2xl text-typography font-bold'>Описание</p>
        <p
          className={`text-kilt-muted ${
            isCollapsible && descriptionCollapsed ? "line-clamp-2" : ""
          }`}
        >
          {listing.description}
        </p>
        {isCollapsible && (
          <button
            className='font-bold text-primary mt-1 cursor-pointer'
            onClick={() => setDescriptionCollapsed(!descriptionCollapsed)}
          >
            {descriptionCollapsed ? "Развернуть" : "Свернуть"}
          </button>
        )}
      </div>
    );
  };
  const renderUserInfo = (listing, desktop = true) => {
    const seller = listing.seller || {};
    const isSpecialist = seller.user_type === "specialist";
    const isAgency = seller.user_type === "agency";
    const goToSeller = () => {
      navigate(`/seller/${seller.id}`);
    };
    if (true) {
      return (
        <div
          className={`shadow-container bg-white flex-col p-5 rounded-xl mb-5 ${
            desktop ? "hidden xl:flex" : "flex xl:hidden"
          }`}
        >
          <p className='font-semibold text-lg flex flex-row items-center'>
            <span className='mr-1'>
              {!isSpecialist && !isAgency
                ? listing?.show_seller_name
                  ? "Собственник недвижимости"
                  : `${seller.lastname || ""} ${seller.firstname || ""}`
                : ""}
              {isSpecialist &&
                `${seller.lastname || ""} ${seller.firstname || ""}`}

              {isAgency && `${seller.firstname || ""}`}

              {/* {isSpecialist
                ? `${seller.lastname || ""} ${seller.firstname || ""}`
                : isAgency
                ? seller.firstname || ""
                : "Собственник недвижимости"} */}
            </span>
            <img src={verified} />
          </p>
          {isSpecialist && (
            <p className='text-[#8794A5]'>Специалист по подбору квартир</p>
          )}
          <a
            className={`${
              !showPhone ? "cursor-pointer" : ""
            } flex-1 xl:flex-initial gradient-button py-2.5 px-6 rounded-xl flex flex-row items-center my-2 justify-center`}
            onClick={() => {
              registerEvents(listing);
              setShowPhone(true);
            }}
          >
            <span className='text-sm font-bold text-white ml-1'>
              {showPhone
                ? listing.seller_phone_number
                : `Показать телефон +7 (***) *** ** **`}
            </span>
          </a>
          {isSpecialist && (
            <BorderedButton
              text='Посмотреть профиль'
              onClick={goToSeller}
              additionalClass='mt-2'
            />
          )}
        </div>
      );
    }
  };

  const openGallery = (index) => () => {
    setGalleryIndex(index);
    setGalleryOpenCounter(galleryOpenCounter + 1);
  };

  const addToFavorites = async (id) => {
    const req = await axios.post(
      `${api.serverUrl}/api/v1/users/sync-favorites`,
      {
        user_id: user.id,
        listing_id: id,
      }
    );

    if (req.status === 200) {
      loadFavorites();
    }
  };

  const removeFromFavorites = async (id) => {};

  const loadFavorites = async () => {
    const req = await axios.get(
      `${api.serverUrl}/api/v1/users/get-favorites/${user.id}`
    );
    setFavorites(req.data.favorites);
  };

  useEffect(() => {
    if (user.id) {
      loadFavorites();
    }
  }, []);

  return (
    <div className='lg:container grid grid-cols-12 '>
      <LoadingWrapper
        loading={!listing.id}
        render={
          <div className='col-span-12 flex flex-row justify-center mt-4'>
            <OrbitProgress size='small' color='#3F4FE0' />
          </div>
        }
      >
        {!!listing.images?.length && (
          <div className='col-span-12 xl:col-span-8 mb-1 xl:mb-0'>
            <img
              className='w-full h-full max-h-[20vh] xl:max-h-[60vh] object-cover xl:rounded-xl cursor-pointer'
              src={utils.singleImageLink(listing.images[0])}
              onClick={openGallery(0)}
            />
          </div>
        )}
        {listing.images?.length > 1 && (
          <div className='col-span-12 xl:col-span-4 flex flex-col xl:pl-2.5'>
            {listing.images.slice(1, 4).map((image, idx) => (
              <img
                className='w-full h-full max-h-[20vh] xl:max-h-[18vh] mb-1 xl:mb-[3vh] object-cover xl:rounded-xl cursor-pointer'
                src={utils.singleImageLink(image)}
                onClick={openGallery(1 + idx)}
              />
            ))}
          </div>
        )}
        {!!!listing.images?.length && (
          <>
            <div className='col-span-12 xl:col-span-8 mb-1 xl:mb-0'>
              <img
                className='w-full h-full max-h-[20vh] xl:max-h-[60vh] object-cover xl:rounded-xl cursor-pointer'
                src={noImage}
                onClick={openGallery(0)}
              />
            </div>
          </>
        )}

        <div className='col-span-12 xl:col-span-8 flex flex-col px-4 xl:pr-4'>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              style={{
                padding: "7px 20px",
                borderRadius: "12px",
                color: favorites?.map((i) => i.id).includes(listing.id)
                  ? "#3F4FE0"
                  : "#000",
                backgroundColor: favorites
                  ?.map((i) => i.id)
                  .includes(listing.id)
                  ? "#3F4FE00D"
                  : "#F5F5F5",
                border: "none",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: "7px",
              }}
              onClick={() => {
                addToFavorites(listing.id);
              }}
            >
              <Icon
                name={
                  favorites?.map((i) => i.id).includes(listing.id)
                    ? "favorite"
                    : "favorite-gray"
                }
                fill='#8794A5'
              />{" "}
              Сохранить
            </button>
          </div>
          <p className='text-[32px] text-typography font-bold'>
            {utils.formatPrice(listing.price)} тг
          </p>
          <div className='flex flex-row'>
            {renderHeader(listing, headerStructure)}
          </div>
          <p className='mt-[6px] text-info-gray mb-5'>
            {listing.address_string}
          </p>
          {renderUserInfo(listing, false)}
          <ListingInfo
            title='О квартире'
            listing={listing}
            keys={infoStructure.filter((prop) => {
              console.log(prop);
              return prop !== "num_floors";
            })}
            renderType='main_info'
          />
          <ListingInfo
            title='О доме'
            listing={listing}
            keys={[
              "residential_complex_name",
              "built_year",
              "construction_type",
            ]}
            renderType={"about_house"}
          />
          {renderDescription(listing)}
          <div className='publishDate'>
            Дата публикации:{" "}
            {listing.publishedAt
              ? dateFormat(listing.publishedAt)
              : dateFormat(listing.createdAt)}
          </div>
        </div>
        <div className='col-span-12 xl:col-span-4 flex flex-col mt-5 xl:mt-0'>
          {renderUserInfo(listing)}
          {disableCalc === true ? null : (
            <ListingCalculator listing={listing} />
          )}
        </div>
        {listing.images?.length ? (
          <ListingGallery
            images={listing.images.map((img) => ({
              id: img.id,
              src: utils.singleImageLink(img),
            }))}
            openCounter={galleryOpenCounter}
            index={galleryIndex}
          />
        ) : null}
        <hr className='col-span-12 my-7' />
        {showSimilar && (
          <div className='col-span-12 grid grid-cols-12'>
            <p className='col-span-12 text-3xl font-semibold text-kilt-black mb-7 pl-4 xl:pl-0'>
              Похожие объявления
            </p>
            <LoadingWrapper
              render={
                <div className='col-span-12 flex flex-col items-center'>
                  <OrbitProgress color='#3F4FE0' size='medium' />
                </div>
              }
              loading={!similar.length}
            >
              {similar.slice(0, 4).map((l) => (
                <>
                  <div className='col-span-3 hidden xl:block'>
                    <SearchResult listing={l} parent='Listing' />
                  </div>
                </>
              ))}
              <div className='col-span-12 flex xl:hidden flex-row overflow-x-scroll ml-4'>
                {similar.map((l) => (
                  <div className='min-w-[75vw]'>
                    <SearchResult listing={l} parent='Listing' />
                  </div>
                ))}
              </div>
            </LoadingWrapper>
          </div>
        )}
      </LoadingWrapper>
    </div>
  );
};

export default Listing;
