import React from "react";
import cl from "./UserData.module.css";
import axios from "axios";
import api from "../../api";
import LoadingWrapper from "../../components/Loading/LoadingWrapper";
import { OrbitProgress } from "react-loading-indicators";

export const UserData = () => {
  const [phone, setPhone] = React.useState("");
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [totalSpent, setTotalSpent] = React.useState(0);

  const SKIP_KEYS = [
    "birthdate",
    "gender",
    "email",
    "instagram",
    "discount",
    "photo",
    "kr_user_modal_status",
    "agent_working_hours",
  ];

  const SKIP_CAMPAIGN_KEYS = [
    "id",
    "updatedAt",
    "status",
    "end_time",
    "disabled",
    "createdAt",
  ];

  const campaignMapKeys = {
    campaign_type: "Тип продвижения",
    listing_id: "ID объявления",
    start_time: "Дата начала",
    paid: "Оплачено",
  };

  const userMapKeys = {
    id: "ID пользователя",
    phone: "Телефон",
    lastname: "Фамилия",
    firstname: "Имя",
    deleted: "Удален",
    user_type: "Тип пользователя",
    kr_name: "Имя на Крыше",
    kr_user_id: "ID пользователя на Крыше",
    kr_agency_id: "ID агентства на Крыше",
    kr_agency_name: "Название агентства на Крыше",
    kr_listing_type: "Тип объявления на Крыше",
    referred_by: "Реферал от ",
    is_blocked: "Заблокирован",
    iin: "ИИН",
    agent_full_address: "Адрес агента",
    agent_working_hours: "Рабочие часы агента",
    agent_city: "Город агента",
    agent_about: "Описание агента",
    agency_verification_status: "Статус верификации агентства",
    createdAt: "Дата создания",
    updatedAt: "Дата обновления",
  };

  const renderValue = (value) => {
    // if type date render date '2024-11-22T08:47:19.891Z'
    if (typeof value === "string" && value.includes("Z")) {
      return new Date(value).toLocaleString();
    }
    const campaign_types = {
      standart: "Silver",
      top: "Gold",
      button: "Button",
      platinum: "Platinum",
    };

    if (campaign_types[value]) {
      return campaign_types[value];
    }

    return value;
  };

  const onSearch = async () => {
    setLoading(true);
    const req = await axios.get(
      `${api.serverUrl}/admin/user-data-by-phone/${phone}`
    );
    console.log(req);
    setData(req.data);
    setLoading(false);
  };

  return (
    <div className={cl.wrapper}>
      <div className={cl.container}>
        <div className={cl.input}>
          <input
            type='phone'
            value={phone}
            placeholder='77077070'
            maxLength={13}
            onChange={(e) => setPhone(e.target.value)}
          />
          <div className={cl.submit}>
            <button onClick={onSearch}>Поиск</button>
          </div>
        </div>
        {data ? (
          <>
            <div className={cl.title}>Данные о пользователе</div>
            <div className={cl["user-data"]}>
              {data &&
                Object.keys(data?.userAccountData).map(
                  (key) =>
                    !SKIP_KEYS.includes(key) && (
                      <div key={key} className={cl["user-data-item"]}>
                        <div className={cl.key}>
                          {userMapKeys[key] ? userMapKeys[key] : key}
                        </div>
                        <div className={cl.value}>
                          {data?.userAccountData[key]
                            ? data.userAccountData[key]
                            : "Нет данных"}
                        </div>
                      </div>
                    )
                )}
              <div className={cl["user-data-item"]}>
                <div className={cl.key}>Общий расход на продвижение</div>
                <div className={cl.value}>
                  {data?.campaignsData.reduce(
                    (acc, item) => acc + item.paid,
                    0
                  )}{" "}
                  ₸
                </div>
              </div>
              <div className={cl["user-data-item"]}>
                <div className={cl.key}>Общее кол-во объявлений</div>
                <div className={cl.value}>{data?.userListingData.length}</div>
              </div>
            </div>
            <div className={cl.title}>Данные о объявлениях</div>
            <div className={cl["user-data"]}>
              {data?.userListingData &&
                data?.userListingData.map((listing) => (
                  <div key={listing} className={cl["user-data-item"]}>
                    <div className={cl.key}>ID</div>
                    <div className={cl.value}>
                      <a
                        href={`https://kilt.kz/listings/${listing.id}`}
                        target='_blank'
                        className={cl.link}
                      >
                        {listing.id}
                      </a>
                    </div>
                  </div>
                ))}
            </div>

            <div className={cl.title}>Данные о продвижении</div>
            <div className={cl["user-data"]}>
              {data?.campaignsData &&
                data?.campaignsData.map((campaign, index) => {
                  return (
                    <div className={cl.comapign}>
                      {index + 1} {campaign?.campaign_type}
                      {Object.keys(campaign).map(
                        (key) =>
                          !SKIP_CAMPAIGN_KEYS.includes(key) && (
                            <div className={cl["user-data-item"]}>
                              <div className={cl.key}>
                                {campaignMapKeys[key]
                                  ? campaignMapKeys[key]
                                  : key}
                              </div>
                              <div className={cl.value}>
                                {key === "listing_id" ? (
                                  <a
                                    href={`https://kilt.kz/listings/${campaign[key]}`}
                                    target='_blank'
                                    className={cl.link}
                                  >
                                    {campaign[key]}
                                  </a>
                                ) : campaign[key] ? (
                                  renderValue(campaign[key])
                                ) : (
                                  "Нет данных"
                                )}
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  );
                })}
            </div>
          </>
        ) : (
          <div className={cl.nothing}>
            {loading ? (
              <OrbitProgress color={"#1A278E"} size={24} />
            ) : (
              "Ничего не найдено"
            )}
          </div>
        )}
      </div>
    </div>
  );
};
