import React, { useEffect } from "react";
import cl from "./Analytics.module.css";
import api from "../../api";
import axios from "axios";

export const Analytics = () => {
  const [dateFrom, setDateFrom] = React.useState("");
  const [dateTo, setDateTo] = React.useState("");
  const [disabled, setDisabled] = React.useState(true);

  useEffect(() => {
    if (dateFrom && dateTo) {
      setDisabled(false);
    }
  }, [dateFrom, dateTo]);

  const generateCSV = (data) => {
    console.log(data);
    if (data.length === 0) return;
    const headers = Object.keys(data[0]).join(","); // Get headers
    const rows = data.map((row) =>
      Object.values(row)
        .map((values) => {
          if (values != null || values != undefined) {
            return `"${String(values).replace(/"/g, '""')}"`;
          } else {
            return "";
          }
        })
        .join(",")
    ); // Convert rows to CSV
    return [headers, ...rows].join("\n"); // Combine headers and rows
  };

  const downloadCSV = (data, filename) => {
    const csvData = generateCSV(data); // Generate CSV string
    const blob = new Blob([csvData], { type: "text/csv" }); // Create a Blob
    const url = URL.createObjectURL(blob); // Create a downloadable link
    const link = document.createElement("a");
    link.href = url;
    link.download = `${filename}.csv`; // Name the file
    link.click(); // Trigger the download
    URL.revokeObjectURL(url); // Clean up
  };

  const handleDetailReport = async () => {
    if (disabled) return;
    setDisabled(true);
    const req = await axios.post(`${api.serverUrl}/admin/get-detailed-report`, {
      dateFrom: dateFrom,
      dateTo: dateTo,
    });

    const data = req.data;

    Object.keys(data).forEach((key) => {
      console.log(key);
      const date = new Date();
      const today = `${
        date.getMonth() + 1
      }-${date.getDate()} ${date.toLocaleTimeString()}`;
      downloadCSV(data[key], `${key}-(${dateFrom}-${dateTo}) ${today}`);
    });
    setDisabled(false);
  };

  const handleGeneralReport = async () => {
    if (disabled) return;
    setDisabled(true);
    const req = await axios.post(`${api.serverUrl}/admin/get-general-report`, {
      dateFrom: dateFrom,
      dateTo: dateTo,
    });

    const data = req.data;

    Object.keys(data).forEach((key) => {
      console.log(key);
      const date = new Date();
      const today = `${
        date.getMonth() + 1
      }-${date.getDate()} ${date.toLocaleTimeString()}`;
      downloadCSV(data[key], `${key} (${dateFrom}-${dateTo}) ${today}`);
    });
    setDisabled(false);
  };

  const handleEventReport = async () => {
    if (disabled) return;
    setDisabled(true);
    const req = await axios.post(`${api.serverUrl}/admin/get-event-report`, {
      dateFrom: dateFrom,
      dateTo: dateTo,
    });

    const data = req.data;

    // date with time
    const date = new Date();
    const today = `${
      date.getMonth() + 1
    }-${date.getDate()} ${date.toLocaleTimeString()}`;
    downloadCSV(data, `event-report-(${dateFrom} ${dateTo}) ${today}`);

    setDisabled(false);
  };

  return (
    <div>
      <div className={cl.inputs}>
        <div className={cl.inputView}>
          <div className={cl.label}>Отчеты от </div>
          <input
            type='date'
            onChange={(e) => {
              setDateFrom(e.target.value);
            }}
          />
        </div>
        <div className={cl.inputView}>
          <div className={cl.label}>Отчеты до </div>
          <input
            type='date'
            onChange={(e) => {
              setDateTo(e.target.value);
            }}
          />
        </div>
      </div>
      <div className={cl.btns}>
        <button
          onClick={handleDetailReport}
          className={disabled ? cl.disabled : cl.btn}
        >
          Загрузить детальный отчет
        </button>
        <button
          onClick={handleGeneralReport}
          className={disabled ? cl.disabled : cl.btn}
        >
          Загрузить общий отчет
        </button>
        <button
          onClick={handleEventReport}
          className={disabled ? cl.disabled : cl.btn}
        >
          Загрузить отчет по событиям
        </button>
      </div>
    </div>
  );
};
