import { Suspense, useEffect, useRef, useState } from "react";
import { Map2Gis } from "../../components/Map2Gis/Map2Gis";

export const SearchWebView = ({}) => {
  const [message, setMessage] = useState(null);
  const [center, setCenter] = useState(null);
  const [height, setHeight] = useState(null);

  const onMapDragEnd = async (e) => {
    console.log("onMapDragEnd");
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        event: "mapMove",
        data: JSON.stringify(e),
      })
    );
  };

  const onClusterClick = async (data) => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        event: "clusterClick",
        data: JSON.stringify(data),
      })
    );
  };

  useEffect(() => {
    const handleMessage = (event) => {
      if (event?.data?.type === "mapResults") {
        setMessage(JSON.parse(JSON.stringify(event.data.data)));
      }
      if (event?.data?.type === "changeCenter") {
        setCenter(JSON.parse(JSON.stringify(event.data.data)));
      }
      if (event?.data?.type === "setHeight") {
        if (event.data != "null") {
          setHeight(event.data.data);
        }
      }
    };

    window.addEventListener("messageFromReactNative", handleMessage);
    return () => {
      window.removeEventListener("messageFromReactNative", handleMessage);
    };
  }, []);

  return (
    <Map2Gis
      results={!!message ? message : []}
      onMapDragEnd={onMapDragEnd}
      onClusterClick={onClusterClick}
      center={center}
      height={height}
    />
  );
};
